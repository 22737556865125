import React from 'react'
import { graphql } from 'gatsby'
import Seo from 'src/components/Seo'
import { Hero, HostSection } from 'src/sections'
import SectionTwoColumns from 'src/components/SectionTwoColumns'
import EventSignupForm from 'src/components/EventSignupForm'
import {
  Section,
  Heading,
  Wysiwyg,
  FlexboxCol,
  FlexboxRow,
  Text,
  Container,
  Link,
} from 'src/components/Base'
import BaseCard from 'src/components/Cards/BaseCard'

import {
  cBenefitCard,
  cBenefitCardWrapper,
  cBenefitText,
  cBenefitIcon,
  cBenefitHeading,
  cSignupFormLabel,
  cSignupFormValue,
  cSignupFormLeftDetails,
  cSignupFormLeftDetailsRow,
} from './styles.module.scss'
import classNames from 'classnames'
import { v4 as uuid } from 'uuid'

const EventSingle = ({ data }) => {
  const eventPost = data.wpEvent
  const hero = eventPost.atspHero
  const event = eventPost.atspEvent
  // TODO: Add translations for coming soon text depending on language
  let eventDate = event.eventDate?.split(' ') || 'Demnächst'
  eventDate = eventDate[0] + ', ' + eventDate[1]
  const host = event.eventHost
  const benefits = event.eventBenefit
  const intro = event.eventIntro
  const signupForm = event.eventSingnupForm
  const eventDetails = { date: event.eventDate, place: event.eventPlace }
  const eventTranslations =
    data.allWp.nodes[0].themeGeneralSettings.atspEventSingleTranslations

  let timeLabel = ''
  if (eventPost.language.locale.startsWith('de') && event.eventDate) {
    timeLabel = 'Uhr'
  }

  const removeAsterisk = (str) => {
    return str.replace(/\*/g, '')
  }

  return (
    <>
      <Seo
        title={eventPost.seo.title}
        locale={eventPost.language.locale}
        description={eventPost.seo.metaDesc}
      />
      <Hero
        imageData={hero.heroImage.localFile}
        dateTitle={`${eventTranslations.eventSingleTranslationsEvent} `}
        date={`${event.eventDate}, ${event.eventDuration}`}
        locationTitle={`${eventTranslations.eventSingleTranslationsLocation} `}
        location={event.eventPlace}
        buttonText={eventTranslations.eventSingleTranslationsSignupButton}
        buttonLink="#signup"
        buttonIsInternal
        heading={hero.heroHeading}
        label={event.eventType}
        eventDetails={eventDetails}
      />
      <Section hasBackground>
        <Heading isCentered color="dark" as="h2" size={2}>
          {intro.heading}
        </Heading>
        <Container>
          <Wysiwyg isCentered>{intro.wysiwyg}</Wysiwyg>
        </Container>
      </Section>
      <Section>
        <FlexboxRow>
          <FlexboxCol className={cBenefitText}>
            <Heading className={cBenefitHeading} as="h3" color="dark" size={3}>
              {benefits.heading}
            </Heading>
            <Wysiwyg>{benefits.wysiwyg}</Wysiwyg>
          </FlexboxCol>
          {benefits.repeater &&
            benefits.repeater.map(({ heading, icon, link }) => (
              <FlexboxCol key={uuid()} className={cBenefitCardWrapper}>
                <BaseCard
                  className={cBenefitCard}
                  headerHasNoPadding
                  backgroundColor="#F7FAFC"
                  hasBorder
                  hasBigPadding
                  body={
                    <>
                      <Heading
                        className={cBenefitHeading}
                        as="h3"
                        size={3}
                        color="dark"
                      >
                        {heading}
                      </Heading>
                      <img
                        className={cBenefitIcon}
                        src={icon.localFile.publicURL}
                        alt={icon.altText}
                      />
                      <Link external hasArrows href={link.url}>
                        {link.title}
                      </Link>
                    </>
                  }
                />
              </FlexboxCol>
            ))}
        </FlexboxRow>
      </Section>
      {host.repeater && (
        <HostSection
          hosts={host.repeater}
          heading={host.heading}
          hasBackground
        />
      )}
      <SectionTwoColumns
        heading={signupForm.heading}
        id="signup"
        headingColor="dark"
        contentLeft={
          <>
            <div className={cSignupFormLeftDetails}>
              <Text className={cSignupFormLeftDetailsRow}>
                <Text as="span" className={cSignupFormLabel}>
                  {eventTranslations.eventSingleTranslationsEvent}
                </Text>
                <Text
                  as="span"
                  className={classNames(cSignupFormValue, 'text-black')}
                >
                  {removeAsterisk(hero.heroHeading)}
                </Text>
              </Text>
              <Text className={cSignupFormLeftDetailsRow}>
                <Text as="span" className={cSignupFormLabel}>
                  {eventTranslations.eventSingleTranslationsDate}
                </Text>
                <Text
                  as="span"
                  className={classNames(cSignupFormValue, 'text-black')}
                >
                  {eventDate + ' ' + timeLabel}
                </Text>
              </Text>
              <Text className={cSignupFormLeftDetailsRow}>
                <Text as="span" className={cSignupFormLabel}>
                  {eventTranslations.eventSingleTranslationsLocation}
                </Text>
                <Text
                  as="span"
                  className={classNames(cSignupFormValue, 'text-black')}
                >
                  {event.eventPlace}
                </Text>
              </Text>
              <Text className={cSignupFormLeftDetailsRow}>
                <Text as="span" className={cSignupFormLabel}>
                  {eventTranslations.eventSingleTranslationsDuration}
                </Text>
                <Text
                  as="span"
                  className={classNames(cSignupFormValue, 'text-black')}
                >
                  {event.eventDuration}
                </Text>
              </Text>
            </div>
            {signupForm.wysiwyg && <Wysiwyg>{signupForm.wysiwyg}</Wysiwyg>}
          </>
        }
        contentRight={
          signupForm.to && (
            <EventSignupForm
              eventId={eventPost.databaseId}
              toEmail={signupForm.to}
              eventDate={event?.eventDate}
              eventTitle={removeAsterisk(hero.heroHeading)}
            />
          )
        }
      />
    </>
  )
}

export const eventQuery = graphql`
  query DefaultEventById($id: String!) {
    allWp {
      nodes {
        themeGeneralSettings {
          atspEventSingleTranslations {
            eventSingleTranslationsDate
            eventSingleTranslationsEvent
            eventSingleTranslationsLocation
            eventSingleTranslationsSignupButton
            eventSingleTranslationsDuration
          }
        }
      }
    }
    wpEvent(id: { eq: $id }) {
      databaseId
      title
      language {
        locale
      }
      seo {
        title
        metaDesc
      }
      atspHero {
        heroHeading
        heroImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, width: 2880, quality: 90)
            }
          }
        }
      }
      atspEvent {
        eventDate
        eventDuration
        eventPlace
        eventType
        eventBenefit {
          heading
          wysiwyg
          repeater {
            heading
            icon {
              localFile {
                publicURL
              }
              altText
            }
            link {
              url
              title
            }
          }
        }
        eventIntro {
          heading
          wysiwyg
        }
        eventSingnupForm {
          heading
          wysiwyg
          to
        }

        eventHost {
          heading
          repeater {
            host {
              ... on WpKontakt {
                id
                atspcontacts {
                  contactData {
                    position
                  }
                  portrait {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: FULL_WIDTH
                          width: 500
                          quality: 90
                        )
                      }
                    }
                  }
                }
                title
              }
            }
            description
          }
        }
      }
    }
  }
`

export default EventSingle
